import React, { useEffect, useState } from "react";
import StockUpdate from "../../../components/dayrade/stock-update";

// import qr_code from "../../../assets/images/dashboard/profile/QR-code.png";

// componets
import Card from "../../../components/bootstrap/card";

import Head from "../../../components/dayrade/common/Head";
import { Col, Row } from "react-bootstrap";
import {
	Commentators,
	FullChartview,
	ShowCharts,
} from "../../../components/dayrade/dashboardComponents";
import Header from "../../../components/partials/pro/headerstyle/header";
import { fetchGraphData, fetchLeaderBoardsHeader, fetchTradeSummaryAnalytics, fetchUserDataApi } from "../../../services/apis/dashboard";
import Loader from "../../../ui/Loader";
import './dashboard.css'


const colors = ["#cdff7b"];

const Dashboard = () => {

	const [showCharts, setShowCharts] = useState(false);
	const [showDifferentCharts, setShowDifferentCharts] = useState(false);
	const [leaderBoardHeader, setLeaderBoardHeader] = useState([]);
	const [tradeAnalytics, setTradeAnalytics] = useState({
		realizedPnl: 0,
		totalBalance: 0,
		totalNoOfTrades: 0,
		totalNotionalTraded: 0,
		totalPnl: 0,
		totalSharesTraded: 0,
		totalStocksTraded: 0,
		unRealizedPnl: 0
	});
	const [selectedUserId, setSelectedUserId] = useState(undefined);
	const [graphData, setGraphData] = useState({
		intervalsXAxis: [],
		balanceYAxis: [],
		maxNoOfTradesArr: [],
		maxNotionalTradedArr: [],
		maxPnlArr: [],
		maxRealizedPnlArr: [],
		maxSharesTradedArr: [],
		maxUnrealizedPnlArr: [],
		maxSymbolCount: []
	});
	const [userData, setUserData] = useState({
		id: null,
		username: null,
		imagePath: null,
		qrCodeLogo: null,
	});
	const [isLoading, setIsLoading] = useState(false);

	const fetchLeaderBoardHeaderData = async () => {
		try {
			const response = await fetchLeaderBoardsHeader();
			if (response?.status === 200) {
				return response.data?.leaderboard;
			}
		} catch (error) {
			console.error('Error fetching leaderboard header:', error);
			return null;
		}
	};

	const fetchTradeAnalyticsData = async (userId) => {
		try {
			setIsLoading(true);
			const response = await fetchTradeSummaryAnalytics(userId);
			if (response?.status === 200) {
				const tradeAnalytics = response?.data?.tradeAnalytics
				setTradeAnalytics({
					realizedPnl: tradeAnalytics.realizedPnl,
					totalBalance: tradeAnalytics.totalBalance,
					totalNoOfTrades: tradeAnalytics.totalNoOfTrades,
					totalNotionalTraded: tradeAnalytics.totalNotionalTraded,
					totalPnl: tradeAnalytics.totalPnl,
					totalSharesTraded: tradeAnalytics.totalSharesTraded,
					totalStocksTraded: tradeAnalytics.totalStocksTraded,
					unRealizedPnl: tradeAnalytics.unRealizedPnl
				});
				setIsLoading(false);
				return;
			}
		} catch (error) {
			console.error('Error fetching trade summary analytics:', error);
			setIsLoading(false);
			return null;
		}
	};

	const fetchGraphDataAnalytics = async (userId) => {
		try {
			const response = await fetchGraphData(userId);

			if (response.status === 200) {
				let data = response.data.tradeGraphData;

				// Initialize temporary arrays
				let balanceArr = [];
				let intervalArr = [];
				let maxNoOfTradesArr = [];
				let maxNotionalTradedArr = [];
				let maxPnlArr = [];
				let maxRealizedPnlArr = [];
				let maxSharesTradedArr = [];
				let maxUnrealizedPnlArr = [];
				let maxSymbolArr = [];

				// Process each data entry
				data.forEach((val) => {
					balanceArr.push(val.balance !== null ? val.balance : 0);
					intervalArr.push(val.interval !== null ? val.interval + ' hrs' : 0 + ' hrs');
					maxNoOfTradesArr.push(val.maxNoOfTrades !== null ? val.maxNoOfTrades : 0);
					maxNotionalTradedArr.push(val.maxNotionalTraded !== null ? val.maxNotionalTraded : 0);
					maxPnlArr.push(val.maxPnl !== null ? val.maxPnl : 0);
					maxRealizedPnlArr.push(val.maxRealizedPnl !== null ? val.maxRealizedPnl : 0);
					maxSharesTradedArr.push(val.maxSharesTraded !== null ? val.maxSharesTraded : 0);
					maxUnrealizedPnlArr.push(val.maxUnrealizedPnl !== null ? val.maxUnrealizedPnl : 0);
					maxSymbolArr.push(val.symbolCount !== null ? val.symbolCount : 0)
				});

				setGraphData({
					intervalsXAxis: intervalArr,
					balanceYAxis: balanceArr,
					maxNoOfTradesArr: maxNoOfTradesArr,
					maxNotionalTradedArr: maxNotionalTradedArr,
					maxPnlArr: maxPnlArr,
					maxRealizedPnlArr: maxRealizedPnlArr,
					maxSharesTradedArr: maxSharesTradedArr,
					maxUnrealizedPnlArr: maxUnrealizedPnlArr,
					maxSymbolCount: maxSymbolArr
				});

				return maxPnlArr;
			}

		} catch (error) {
			console.error('Error fetching trade summary analytics:', error);
			return null;
		}
	}

	const fetchUserData = async (userId) => {

		try {

			const response = await fetchUserDataApi(userId);

			if (response.status === 200) {
				const data = response?.data?.data
				setUserData({
					id: data?.id,
					username: data?.user_name,
					imagePath: data?.profile?.profile_image_path,
					qrCodeLogo: data?.qr_code_logo
				})
			}

		} catch (error) {
			console.error('Error fetching user data:', error);
			return null;
		}
	}

	const row = [
		{
			title: "Total P&L",
			subTitle: "Available to pay out",
			value: "$" + tradeAnalytics.totalPnl,
			buttonValue: "View",
			chartData: graphData.maxPnlArr,
			series: [
				{
					name: "Total P&LL",
					data: graphData.maxPnlArr,
				},
			],
		},
		{
			title: "Total Shares Traded",
			subTitle: "Available to pay out",
			value: tradeAnalytics.totalSharesTraded,
			buttonValue: "View",
			chartData: graphData.maxSharesTradedArr,
			series: [
				{
					name: "Total Shares Traded",
					data: graphData.maxSharesTradedArr,
				},
			],
		},
		{
			title: "USD Balance",
			subTitle: "Available to pay out",
			value: "$" + tradeAnalytics.totalBalance,
			buttonValue: "Actual Balance",
			chartData: graphData.balanceYAxis,
			series: [
				{
					name: "USD Balance",
					data: graphData.balanceYAxis,
				},
			],
		},
		{
			title: "Realized P&L",
			subTitle: "Available to pay out",
			value: "$" + tradeAnalytics.realizedPnl,
			buttonValue: "View",
			// chartData: [40, 50, 60, 80, 20],
			chartData: graphData.maxRealizedPnlArr,
			series: [
				{
					name: "Realized P&L",
					data: graphData.maxRealizedPnlArr,
				},
			],
		},
		{
			title: "No. of Stocks Traded",
			subTitle: "Available to pay out",
			value: tradeAnalytics.totalStocksTraded,
			buttonValue: "View",
			chartData: graphData.maxSymbolCount,
			series: [
				{
					name: "No. of Stocks Traded",
					data: graphData.maxSymbolCount,
				},
			],
		},
		{
			title: "No. of Trades",
			subTitle: "Available to pay out",
			value: tradeAnalytics.totalNoOfTrades,
			buttonValue: "View",
			chartData: graphData.maxNoOfTradesArr,
			series: [
				{
					name: "No. of Trades",
					data: graphData.maxNoOfTradesArr,
				},
			],
		},
		{
			title: "Unrealized P&L",
			subTitle: "Available to pay out",
			value: "$" + tradeAnalytics.unRealizedPnl,
			buttonValue: "View",
			chartData: graphData.maxUnrealizedPnlArr,
			series: [
				{
					name: "Unrealized P&L",
					data: graphData.maxUnrealizedPnlArr,
				},
			],
		},
		{
			title: "Total Notional Traded",
			subTitle: "Available to pay out",
			value: tradeAnalytics.totalNotionalTraded,
			buttonValue: "View",
			chartData: graphData.maxNotionalTradedArr,
			series: [
				{
					name: "Total Notional Traded",
					data: graphData.maxNotionalTradedArr,
				},
			],
		},
	];

	const [chartData, setChartData] = useState(row[0].chartData);
	const [chartTitle, setChartTitle] = useState(row[0].title);
	const [selectedCard, setSelectedCard] = useState(row[0].title);


	useEffect(() => {
		const fetchData = async () => {

			// Fetch leaderboard header
			const leaderBoardHeader = await fetchLeaderBoardHeaderData();
			if (leaderBoardHeader) {
				setLeaderBoardHeader(leaderBoardHeader);
			}

			const tradeAnalytics = await fetchTradeAnalyticsData(selectedUserId);

			const graphDataResp = await fetchGraphDataAnalytics(selectedUserId);
			if (graphDataResp) {
				const defaultCard = row[0]; // Assuming the first card is the default one
				defaultCard.chartData = graphDataResp
				setChartData(defaultCard.chartData);
				setChartTitle(defaultCard.title);
				setSelectedCard(defaultCard.title);
			}

			await fetchUserData(selectedUserId)
		};

		fetchData();
	}, [selectedUserId]);


	const chart = {
		options: {
			colors: colors,
			chart: {
				toolbar: {
					show: false,
				},
			},
			forecastDataPoints: {
				count: 0,
			},
			stroke: {
				width: 1.5,
				curve: "smooth",
			},
			grid: {
				show: true,
				strokeDashArray: 0,
			},
			markers: {
				// size: 6,
				colors: "#FFFFFF",
				strokeColors: colors,
				strokeWidth: 0.5,
				strokeOpacity: 0.9,
				strokeDashArray: 0,
				fillOpacity: 0,
				shape: "circle",
				radius: 2,
				offsetX: 0,
				offsetY: 0,
			},
			xaxis: {
				categories: graphData.intervalsXAxis,
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				tooltip: {
					enabled: false,
				},
			},
		},
		series: [
			{
				name: chartTitle,
				data: chartData,
			},
		],
	};

	const [isZoomed, setIsZoomed] = useState(false);

	const handleAnimationEnd = () => {
		// setIsZoomed((prevState) => !prevState);
		setIsZoomed(true);
	};

	const handleCardClick = (item) => {
		setChartData(item.chartData);
		setChartTitle(item.title);
		setSelectedCard(item.title);
	};

	const handleToggle = () => {
		setShowCharts((prevState) => !prevState);
		setShowDifferentCharts(false);
	};

	const handleToggleCharts = (item) => {
		handleCardClick(item);
		setShowDifferentCharts((prevState) => !prevState);
	};

	const handleHeaderItemClick = ((userId) => {
		setSelectedUserId(userId)
		fetchTradeAnalyticsData(userId)
		fetchGraphDataAnalytics(userId)
		fetchUserData(userId)
	});




	return (

		<>
			<div
				className={`position-relative dayrade-header-conatiner tw-overflow-hidden tw-rounded`}
			>
				<Header leaderBoardHeader={leaderBoardHeader} onHeaderItemClick={handleHeaderItemClick} />
			</div>
			<Head title="Dashboard" />
			{
				isLoading ? (
					<Loader />
				) : (
					<>

						<button
							className="tw-p-2 tw-bg-primary-dark tw-text-black tw-rounded tw-mt-2"
							onClick={handleToggle}
						>
							{showCharts ? "Show Small Cards" : "Show Charts"}
						</button>
						<Row className="g-2 tw-mt-2">
							{showDifferentCharts ? (
								<FullChartview
									chart={chart}
									row={row}
									handleCardClick={handleCardClick}
									chartTitle={chartTitle}
									showDifferentCharts={showDifferentCharts}
								/>
							) : (
								<ShowCharts
									showCharts={showCharts}
									chartTitle={chartTitle}
									handleCardClick={handleCardClick}
									handleToggleCharts={handleToggleCharts}
									chart={chart}
									row={row}
									selectedCard={selectedCard}
									showDifferentCharts={showDifferentCharts}
								/>
							)}
							<Col
								xs={12}
								md={3}
								className={`${showDifferentCharts ? "tw-mb-8" : "tw-mb-2"}`}
							>
								<Card className="h-100">
									<Card.Body className="tw-flex tw-flex-col tw-items-center tw-gap-5">
										<svg
											width="242"
											height="64"
											viewBox="0 0 242 64"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g clipPath="url(#clip0_119_7439)">
												<path
													d="M43.9797 8.691C43.9797 13.2734 40.2733 16.9982 35.7135 16.9982C31.1537 16.9982 27.4473 13.2734 27.4473 8.691C27.4473 4.1086 31.1409 0.383789 35.7135 0.383789C40.286 0.383789 43.9797 4.1086 43.9797 8.691Z"
													fill="#78EEC5"
												/>
												<path
													d="M22.0088 51.5063H37.5733V62.4375H0.114258L17.5509 36.2359H2.48329V25.3047H39.3055L22.0088 51.5063Z"
													fill="white"
												/>
												<path
													d="M59.0484 25.3047H46.0059V62.4503H59.0484V25.3047Z"
													fill="white"
												/>
												<path
													d="M67.4034 25.3052H80.446V29.862C83.9996 26.4316 87.9862 24.7164 92.4059 24.7164C97.8827 24.7164 102.06 27.0332 104.939 31.6668C107.868 26.982 112.046 24.6396 117.472 24.6396C119.204 24.6396 120.835 24.8956 122.376 25.3948C123.917 25.9068 125.229 26.7004 126.337 27.7884C127.445 28.8764 128.311 30.2844 128.96 32.0252C129.61 33.766 129.928 35.8652 129.928 38.3228V62.438H116.886V44.1213C116.886 42.2396 116.759 40.6908 116.491 39.4876C116.224 38.2844 115.842 37.3372 115.332 36.6588C114.823 35.9804 114.237 35.5324 113.562 35.2892C112.887 35.046 112.173 34.9308 111.396 34.9308C107.219 34.9308 105.13 38.0028 105.13 44.1213V62.438H92.0875V44.1213C92.0875 42.2396 91.9601 40.678 91.7308 39.4492C91.4888 38.2204 91.1449 37.2348 90.6864 36.518C90.2279 35.7884 89.642 35.302 88.916 35.0332C88.19 34.7644 87.3493 34.6364 86.3941 34.6364C85.5789 34.6364 84.8147 34.7516 84.0887 34.9948C83.3627 35.238 82.7259 35.7116 82.1782 36.4028C81.6305 37.1068 81.1974 38.0668 80.879 39.2956C80.5733 40.5244 80.4078 42.1372 80.4078 44.1084V62.4252H67.3652V25.2796L67.4034 25.3052Z"
													fill="white"
												/>
												<path
													d="M166.292 25.3054H179.335V31.3854C180.723 29.171 182.43 27.4686 184.455 26.2782C186.48 25.0878 188.823 24.499 191.511 24.499H192.632C193.039 24.499 193.511 24.5502 194.033 24.6398V37.1582C192.301 36.2878 190.428 35.8526 188.416 35.8526C185.385 35.8526 183.117 36.7615 181.602 38.5663C180.086 40.371 179.335 43.0206 179.335 46.4894V62.4126H166.292V25.267V25.3054Z"
													fill="white"
												/>
												<path
													d="M228.512 25.3053V29.0045C225.48 25.7661 221.672 24.1533 217.061 24.1533C214.323 24.1533 211.826 24.6781 209.572 25.7149C207.318 26.7517 205.369 28.1725 203.739 29.9517C202.108 31.7437 200.835 33.8301 199.918 36.2109C199 38.6045 198.555 41.1261 198.555 43.7757C198.555 46.6301 199.026 49.2541 199.956 51.6733C200.898 54.0925 202.197 56.1917 203.879 57.9709C205.56 59.7629 207.534 61.1453 209.789 62.1309C212.043 63.1165 214.514 63.6157 217.214 63.6157C221.965 63.6157 225.735 61.8493 228.524 58.3293V62.4509H241.631V25.3053H228.524H228.512ZM220.513 52.1725C215.953 52.1725 212.247 48.4477 212.247 43.8653C212.247 39.2829 215.953 35.5581 220.513 35.5581C225.073 35.5581 228.779 39.2701 228.779 43.8653C228.779 48.4605 225.073 52.1725 220.513 52.1725Z"
													fill="white"
												/>
												<path
													d="M152.587 36.2754V62.4386H139.506V36.2754H135.176V25.3058H139.506V14.7842H152.587V25.3058H160.038V36.2754H152.587Z"
													fill="white"
												/>
											</g>
											<defs>
												<clipPath id="clip0_119_7439">
													<rect width="242" height="64" fill="white" />
												</clipPath>
											</defs>
										</svg>

										<div className="cs-container">
											{/* First image without animation */}
											<div className="qr-div">
												<img src={userData.qrCodeLogo} alt="First" />
											</div>

											{
												(userData.imagePath !== null && userData.imagePath !== '') && (
													<div
														className={`profile-image ${!isZoomed ? 'zoom-out' : 'zoom-in'}`}
														onAnimationEnd={handleAnimationEnd}
													>
														<img src={`${process.env.REACT_APP_IMAGE_BASE_URL}${userData.imagePath}`} alt="" crossOrigin="anonymous" />
													</div>
												)
											}

										</div>
										<h6 className="tw-text-primary-dark">@{userData.username}</h6>
									</Card.Body>
								</Card>
							</Col>
						</Row>
						<div>
							<StockUpdate />
						</div>
						<Commentators />
					</>
				)
			}
		</>
	);
};

export default Dashboard;
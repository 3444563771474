export const companyToSymbol = {
    "Caterpillar": "CAT",
    "Travelers": "TRV",
    "Intel": "INTC",
    "American Express": "AXP",
    "IBM": "IBM",
    "Home Depot": "HD",
    "McDonald's": "MCD",
    "Walt Disney": "DIS",
    "Verizon": "VZ",
    "Walmart": "WMT",
    "Goldman Sachs": "GS",
    "Chevron": "CVX",
    "UnitedHealth": "UNH",
    "3M": "MMM",
    "Microsoft": "MSFT",
    "Nike": "NKE",
    "Amgen": "AMGN",
    "Visa": "V",
    "Johnson & Johnson": "JNJ",
    "Merck": "MRK",
    "Salesforce": "CRM",
    "Cisco": "CSCO",
    "Procter & Gamble": "PG",
    "Coca-Cola": "KO",
    "Apple": "AAPL",
    "Dow": "DOW",
    "Honeywell": "HON",
    "Amazon": "AMZN",
    "JPMorgan Chase": "JPM",
    "Boeing": "BA"
  };
  
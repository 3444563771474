import React, { useEffect, useState } from 'react'

// images
import commentator_1 from '../../../assets/images/dashboard/commentators/commentator-1.png';
import commentator_2 from '../../../assets/images/dashboard/commentators/commentator-2.png';
import commentator_3 from '../../../assets/images/dashboard/commentators/commentator-3.png';
import commentator_4 from '../../../assets/images/dashboard/commentators/commentator-4.png';
import { fetchStreamsApi } from '../../../services/apis/stream';



const Commentators = () => {

    const commentators = [
        {
          name: "Mark",
          id: "@x-change",
          img: commentator_1,
        },
        {
          name: "Ernie",
          id: "@the_juice",
          img: commentator_2,
        },
        {
          name: "Mollie",
          id: "@mo_lee",
          img: commentator_3,
        },
        {
          name: "Bernie",
          id: "@evil_twin",
          img: commentator_4,
        },
      ]

      const [streamData, setStreamData] = useState([]);

      const fetchAllStreams = async () => {
        try {

          const response = await fetchStreamsApi();
          if (response.status === 200) {
            setStreamData(response.data.data);
          }

        } catch (error) {
          console.log('error=>', error);
          return;
        }
      }

      useEffect(() => {
        fetchAllStreams()
      }, []);


  return (
    <div className='tw-grid tw-grid-cols-4 tw-gap-2 tw-my-2'>
        {/* {
          commentators.map((elem,index)=> {
            return(
              <div key={index} className='tw-overflow-hidden tw-rounded-md tw-border tw-relative tw-border-[#9E9E9F]'>
                <div className='tw-px-2 tw-absolute tw-flex tw-items-center tw-justify-between tw-w-full tw-bottom-3'>
                  <div className='tw-h-9 tw-w-9 tw-bg-primary-dark tw-opacity-0'></div>
                  <div style={{boxShadow: "#FFFFFF80 0px 2px 0px 0px"}} className='tw-bg-[#3E3E3F] tw-py-1 tw-px-4 tw-rounded'>
                    <h6 className='tw-text-base tw-leading-none'>{elem.name}</h6>
                    <small className='text-xs tw-text-primary-dark tw-leading-none'>{elem.id}</small>
                  </div>
                </div>
                <img src={elem.img} alt={`${elem.name} image`} />
                </div>
            )
          })
        } */}
        {
          streamData.length > 0 ? (
            streamData.map((val) => {
              return(
                <div key={val.id} className='tw-overflow-hidden tw-rounded-md tw-border tw-relative tw-border-[#9E9E9F]'>
                <div className='tw-px-2 tw-absolute tw-flex tw-items-center tw-justify-between tw-w-full tw-bottom-3'>
                  <div className='tw-h-9 tw-w-9 tw-bg-primary-dark tw-opacity-0'></div>
                  <div style={{boxShadow: "#FFFFFF80 0px 2px 0px 0px"}} className='tw-bg-[#3E3E3F] tw-py-1 tw-px-4 tw-rounded'>
                    <h6 className='tw-text-base tw-leading-none'>{val.streamPlatform}</h6>
                    {/* <small className='text-xs tw-text-primary-dark tw-leading-none'>{val.id}</small> */}
                  </div>
                </div>
                <iframe src={val.streamLink} height="280px" frameborder="0"></iframe>
                {/* <iframe src="https://www.youtube.com/embed/BctQTzQ3zh0?si=USiYomNLtR_Ij02o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                </div>
              )
            })
          ) : (
            // <h1 className='text-center' style={{fontSize:'1.5rem'}}>No Streams Available for now</h1>
            null
          )
        }
        
      </div>
  )
}

export default Commentators
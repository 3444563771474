import { axiosPrivate } from "../../utilities/axiosInstances"

export const fetchLeaderBoardsHeader = async () => {        
        const response = await axiosPrivate.get(`/v1/trade/fetch-top-ten-leaderboard`);
        return response;
}

export const fetchTradeSummaryAnalytics = async (userId) => {
        const response = await axiosPrivate.get(`/v1/trade/fetch-trade-summaries?userId=${userId}`);
        return response;
}

export const fetchGraphData = async (userId) => {
        const response = await axiosPrivate.get(`/v1/trade/fetch-graph-data?userId=${userId}`);
        return response;
}

export const fetchUserDataApi = async (userId) => {
        const response = await axiosPrivate.get(`/v1/user/dynamic-user-profile?userId=${userId}`);
        return response;
}

export const fetchMonthlyPnlApi = async (userId) => {
        const response = await axiosPrivate.get(`/v1/trade/fetch-monthly-pnl?userId=${userId}`);
        return response;
}
import React, { memo, useEffect, useState } from 'react';
// componets
import Card from '../../components/bootstrap/card';
import Marquee from 'react-fast-marquee';
import { fetchMarketDataApi } from '../../services/apis/marketData';
import { companyToSymbol } from './companyToSymbol';

// const data = [
//   { symbol: 'TSLA', change: '-0.37%' },
//   { symbol: 'COMP', change: '+1.13%' },
//   { symbol: 'AMZN', change: '-0.55%' },
//   { symbol: 'LBR', change: '+1.30%' },
//   { symbol: 'AMZN', change: '+0.25%' },
//   { symbol: 'AAPL', change: '-0.36%' },
//   { symbol: 'LBR', change: '+1.40%' },
// ];

const isStockTrendPositive = (trend) => {
  // return parseFloat(trend.substring(0, trend.length - 1)) > 0;
  return trend > 0;
};


const getSymbol = (name) => {
  return companyToSymbol[name] || name;
}

const StockUpdate = memo(() => {

  const [marketData, setMarketData] = useState([]);

  const fetchMarketData = async () => {
    try {

      const response = await fetchMarketDataApi();

      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          // setMarketData([...response.data[0]?.topGainers, ...response.data[0]?.topLosers])
          if (response.data && response.data.length > 0) {
            const gainers = response.data[0]?.topGainers || [];
            const losers = response.data[0]?.topLosers || [];
    
            // Handle the case where both arrays might be empty
            if (gainers.length === 0 && losers.length === 0) {
              setMarketData([]); // If both are empty, set marketData to an empty array
            } else if (gainers.length === 0) {
              setMarketData(losers); // If gainers are empty, show only losers
            } else if (losers.length === 0) {
              setMarketData(gainers); // If losers are empty, show only gainers
            } else {
              // Interleave both arrays if neither is empty
              const interleaved = [];
              const maxLength = Math.max(gainers.length, losers.length);
              let count = 0;
              for (let i = 0; i < maxLength; i++) {
                if (i < gainers.length) {
                  interleaved.push(gainers[i]);
                  count++
                }
                if (i < losers.length) {
                  interleaved.push(losers[i]);
                  count++
                }
              }
    
              setMarketData(interleaved);
            }
          } else {
            setMarketData([]); // If response.data is empty, set marketData to an empty array
          }
        }
      }

    } catch (error) {
      console.log('error in fetching markter data', error);
    }
  }

  useEffect(() => {
    fetchMarketData()
  }, [])


  return (
    // <div className="dayrade-stock-update-container fixedbottom ">
    //   <Marquee>
    //     {data.map((stock, index) => (
    //       <Card.Body
    //         key={`${stock.symbol}-${index}`}
    //         className={`dayrade-stock-update-card ${isStockTrendPositive(stock.change)
    //             ? 'dayrade-stock-update-card-positive'
    //             : 'dayrade-stock-update-card-negative'
    //           } tw-font-medium tw-mt-2`}
    //       >
    //         <span>{stock.symbol}</span>
    //         <span>{stock.change}</span>
    //       </Card.Body>
    //     ))}
    //   </Marquee>
    // </div>
    marketData.length > 0 ? (
      <div className="dayrade-stock-update-container fixedbottom ">
        <Marquee>
          {marketData.map((val, index) => (
            <Card.Body
              key={`${val.name}-${index}`}
              className={`dayrade-stock-update-card ${isStockTrendPositive(val.changePercent)
                ? 'dayrade-stock-update-card-positive'
                : 'dayrade-stock-update-card-negative'
                } tw-font-medium tw-mt-2`}
            >
              <span>{getSymbol(val.name)}</span>
              <span>{isStockTrendPositive(val.changePercent) ? '+' + val.changePercent : val.changePercent}</span>
            </Card.Body>
          ))}
        </Marquee>
      </div>
    ) : null
  );
});

export default StockUpdate;

import React, { memo } from "react";

// react-bootstrap
import { Container, Image } from "react-bootstrap";

// img
import participant1 from "../../../../assets/images/dashboard/participants/participant1.png";
import participant2 from "../../../../assets/images/dashboard/participants/participant2.png";
import participant3 from "../../../../assets/images/dashboard/participants/participant3.png";
import participant4 from "../../../../assets/images/dashboard/participants/participant4.png";
import participant5 from "../../../../assets/images/dashboard/participants/participant5.png";
import participant6 from "../../../../assets/images/dashboard/participants/participant6.png";
import participant7 from "../../../../assets/images/dashboard/participants/participant7.png";
import participant8 from "../../../../assets/images/dashboard/participants/participant8.png";
import participant9 from "../../../../assets/images/dashboard/participants/participant9.png";
import participant10 from "../../../../assets/images/dashboard/participants/participant10.png";

const Header = memo(({ leaderBoardHeader, onHeaderItemClick }) => {
	return (
		<Container fluid className="dayrade-header p-2">
			<div className="row h-100 ps-5">
				<div className=" col-lg-1 col-md-1 d-flex align-items-center justify-content-start">
					<div className="d-flex flex-column">
						<p
							className="header-title-text"
							style={{
								fontSize: "15.71px",
							}}
						>
							Leaders
						</p>
						<p
							className="header-title-text"
							style={{
								fontSize: "10px",
							}}
						>
							By Earnings
						</p>
					</div>
				</div>
				<div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center left-shadow">
					<div className="d-flex overflow-hidden ">
						{
							leaderBoardHeader.length > 0 ? (
								leaderBoardHeader.map((val) => {
									return (
										<div key={val?.userId} style={{ cursor: 'pointer' }}>
											<div
												className="surprise-bubble"
												onClick={(e) => {
													// Remove 'active-hover' from all bubbles
													document.querySelectorAll('.surprise-bubble').forEach(bubble => {
														bubble.classList.remove('active-hover');
													});

													// Add 'active-hover' to the clicked bubble
													e.currentTarget.classList.add('active-hover');

													// Trigger the onHeaderItemClick function
													onHeaderItemClick && onHeaderItemClick(val?.userId);
												}}
											>
												<img
													src={val?.profileImagePath === null
														? '/images/defaultuser.jpeg'
														: `${process.env.REACT_APP_IMAGE_BASE_URL}${val?.profileImagePath}`}
													alt={val?.userName}
													crossOrigin="anonymous"
													style={{ objectFit: 'cover' }}
												/>												<div>
													<span>{val?.userName}</span>
													<br />
													<span style={{ color: 'rgb(205, 255, 123)' }}>{"$ " + val?.TotalPnl}</span>
												</div>
											</div>
										</div>
									)
								})
							) : (
								<h1>No Leaders for now. Stay Tuned.</h1>
							)
						}
					</div>
				</div>
				<div className="col-lg-4 col-md-4 d-flex align-items-center justify-content-end">
					<section className="left-right-shadow ">
						<div>
							<section className="news-message">
								<p
									style={{
										color: "#CDFF7B",
										fontSize: "20px",
										fontWeight: "400",
									}}
								>
									Something is always happening here
								</p>
							</section>
							<section className="news-message">
								<p
									style={{
										color: "#CDFF7B",
										fontSize: "20px",
										fontWeight: "400",
									}}
								>
									Something is always happening here
								</p>
							</section>
						</div>
					</section>
				</div>
			</div>
		</Container>
	);
});

Header.displayName = "Header";
export default Header;
